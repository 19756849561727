import React from "react";
import { BasicTemplate } from "@templates/BasicTemplate";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReviewsSection } from "@components/molecules/ReviewsSection";
import { Contact as ContactSection } from "@components/molecules/Contact";
import { graphql } from "gatsby";
import styled from "styled-components";
import { breakpoints } from "@theme/breakpoints";

const StyledContactSection = styled(ContactSection)`
  margin: 180px auto 0 !important;

  ${breakpoints.tabletMax`
    margin: 150px auto 0!important;
  `};

  ${breakpoints.tabletMax`
    margin: 150px auto 0!important;
  `};

  ${breakpoints.phoneMax`
    margin: 120px auto 0!important;
  `}
`;

const Contact = () => {
  const { t } = useTranslation("common");

  return (
    <BasicTemplate
      title={t("title-contact")}
      description={t("description-contact")}
      isAnotherThanIndex
    >
      <StyledContactSection />
      <ReviewsSection secondary />
    </BasicTemplate>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Contact;
